* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  /* height: 100vh;
   width: 100%; */
}

body {
  background: #fff;
  color: #333;
  font-size: 16px;
  font-weight: 300;
  font-family: "Helvetica Neue";
}

.app {
  display: flex;
  flex-flow: column wrap;
  /* height: 100vh;
   width: 100%; */
}

.header {
  background: #387ef5;
  display: flex;
  height: 44px;
  width: 100%;
}

.header .title {
  color: white;
  font-size: 1.1rem;
  margin: auto;
  text-align: center;
}

.main {
  margin: 0 auto;
  /* max-width: 500px; */
  /* width: 100%; */
}

.display {
  color: #292929;
}

.display .state {
  display: block;
  font-size: 1rem;
  padding: 10px 0;
  text-align: center;
}

.display .segments {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #ff9d0050;
  border-radius: 25px;
  width: 101px;
  height: 35px;
}

.display .mins,
.display .secs {
  color: #ff9d00;
  font-size: 20px;
  font-family: "Montserrat";
  font-weight: normal;
}

.display .millis {
  font-size: 30px;
  align-self: flex-end;
}

.actions {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.actions .btnch {
  margin: 0 5px;
}

.btnch {
  background: #387ef5;
  border: solid 1px #387ef5;
  border-radius: 7px;
  color: white;
  cursor: pointer;
  font-size: 0.8em;
  outline: none;
  padding: 10px 50px;
  transition: 300ms all;
}

.btnch:hover {
  opacity: 0.9;
}

.btnch.disabled {
  opacity: 0.5;
}
