.imgphone {
  display: flex;
  margin: 0 auto;
  width: 40px;
}
.divOtp {
  padding-top: 25%;
}
.textSaisieCode {
  text-align: center;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  height: 52px;
  margin-bottom: 84px;
}
.textSaisieCodeAr {
  text-align: center;
  font-family: cairo;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  height: 52px;
  padding: 1px;
  margin-bottom: 68px;
}
.textCode {
  text-align: center;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
}
.textCodeAr {
  text-align: center;
  font-family: cairo;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
}
.styleNum {
  letter-spacing: 0;
  color: rgba(255, 157, 0, 1);
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}
.styleNumAr {
  letter-spacing: 0;
  color: rgba(255, 157, 0, 1);
  font-family: cairo;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}
.divcodeOtp {
  display: flex;
  justify-content: center;
  padding-bottom: 8%;
}
.divcodeAR {
  display: flex;
  justify-content: center;
  padding-bottom: 8%;
  direction: ltr;
}
.textRenvoiCode {
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  padding-left: 4%;
}
.textRenvoiCodeAr {
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  font-family: cairo;
  font-weight: 400;
  font-size: 16px;
  padding-right: 7%;
}
.textRenvoyer {
  letter-spacing: 0;
  color: rgba(255, 157, 0, 1);
  text-transform: uppercase;
  opacity: 1;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 15px;
  padding-left: 4%;
  cursor: pointer;
}
.textRenvoyerAr {
  letter-spacing: 0;
  color: rgba(255, 157, 0, 1);
  text-transform: uppercase;
  opacity: 1;
  font-family: cairo;
  font-weight: bold;
  font-size: 15px;
  padding-right: 7%;
  cursor: pointer;
}
.divBoutonOtp {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 3%;
  padding-left: 3%;
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .imgphone {
    width: 50px;
  }
  .textSaisieCode {
    font-size: 50px;
  }
  .textSaisieCodeAr {
    font-size: 50px;
  }
  .textCode {
    font-size: 32px;
  }
  .textCodeAr {
    font-size: 32px;
  }
  .styleNum {
    font-size: 32px;
  }
  .textRenvoiCode {
    font-size: 32px;
  }
  .textRenvoiCodeAr {
    font-size: 32px;
  }
  .textRenvoyer {
    font-size: 30px;
  }
  .textRenvoyerAr {
    font-size: 30px;
  }
  .styleNumAr {
    font-size: 32px;
  }
  .divOtp {
    padding-top: 19%;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  .divOtp {
    padding-top: 19%;
  }
  .styleNumAr {
    font-size: 32px;
  }
  .textRenvoyer {
    font-size: 30px;
  }
  .textRenvoyerAr {
    font-size: 30px;
  }
  .textRenvoiCode {
    font-size: 32px;
  }
  .textRenvoiCodeAr {
    font-size: 32px;
  }
  .styleNum {
    font-size: 32px;
  }
  .textCode {
    font-size: 32px;
  }
  .textCodeAr {
    font-size: 32px;
  }
  .textSaisieCode {
    font-size: 50px;
  }
  .textSaisieCodeAr {
    font-size: 50px;
  }
  .imgphone {
    width: 50px;
  }
  .textCode {
    font-size: 32px;
  }
}
.point {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ff9d00;
  position: relative;
  animation: fadein 2s;
  animation-iteration-count: infinite;
}
.point1 {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ff9d00;
  position: relative;
  animation: fadein1 2s;
  animation-iteration-count: infinite;
}
.imgleft {
  position: absolute;
  position: absolute;
  padding: 12px;
  cursor: pointer;
}
.imgright {
  position: absolute;
  position: absolute;
  padding: 20px;
  cursor: pointer;
  /* margin-right: 350px; */
  transform: rotate(180deg);
}
.point2 {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ff9d00;
  position: relative;
  animation: fadein2 2s;
  animation-iteration-count: infinite;
}

@keyframes fadein {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadein1 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadein2 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.CeC {
  color: #ff9d00;
  position: relative;
  animation: fadein 2s;
  animation-iteration-count: infinite;
}
