.BienvenueJawhara {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  opacity: 1;
  padding-left: 5%;
  width: 100%;
  line-height: 140%;
  padding-right: 5%;
  text-align: center;
  margin-bottom: 5px;
}
.BienvenueJawhara2 {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  padding-left: 5%;
  width: 100%;
  line-height: 140%;
  padding-right: 5%;
  text-align: center;
  margin-top: 0px;
}
.BienvenueJawharaAr2 {
  font-family: cairo;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  padding-left: 5%;
  width: 100%;
  line-height: 140%;
  padding-right: 5%;
  text-align: center;
  margin-top: 0px;
}
.home {
  background-color: #0066b5;
  max-width: 400px;
  margin: 0 auto;
  min-height: 100vh;
}
.BienvenueJawharaAr {
  font-family: cairo;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  opacity: 1;
  padding-left: 5%;
  width: 100%;
  line-height: 140%;
  padding-right: 5%;
  text-align: center;
  margin-bottom: 5px;
}
.labelSaisie {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  opacity: 1;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
}
.labelSaisieAr {
  font-family: cairo;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  opacity: 1;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
}
.logitttext {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  opacity: 1;
  line-height:50px;
  padding-left: 3%;
  padding-right: 3%;
}
.logitttextAr {
  font-family: cairo;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  opacity: 1;
  padding-left: 3%;
  padding-right: 3%;
  line-height:50px;
}
.imgLogoLogin {
  justify-content: center;
  display: flex;
  opacity: 1;
  padding-top: 10%;
  padding-bottom: 2%;
}
.divInput {
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  position: relative;
}
.inputTel {
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  border: 1px solid rgba(227, 227, 227, 1);
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  padding-left: 19%;
  height: 50px;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0;
  color: rgba(127, 127, 127, 1);
  text-transform: capitalize;
  outline: none;
}
.inputTelAr {
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  border: 1px solid rgba(227, 227, 227, 1);
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  /* padding-right: 5%; */
  height: 50px;
  font-family: cairo;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0;
  color: rgba(127, 127, 127, 1);
  text-transform: capitalize;
  outline: none;
  direction: ltr;
  padding-left: 16%;
}
.divBouton {
  display: flex;
  /* flex-direction: row-reverse; */
  padding-top: 15%;
  padding-right: 5%;
  padding-left: 5%;
  justify-content: center;
}
.btn {
  background: rgba(255, 157, 0, 1) 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  text-align: center;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  border: 0px solid;
  height: 45px;
  width: 146px;
}
.btnAr {
  background: rgba(255, 157, 0, 1) 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  text-align: center;
  font-family: cairo;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  border: 0px solid;
  height: 50px;
  width: 146px;
}
.inscLogin {
  display: flex;
  flex-direction: row;
  padding-top: 5%;
}
.spanInput {
  position: absolute;
  margin-left: 5%;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0;
  color: #afafaf;
  text-transform: capitalize;
  opacity: 1;
}
.spanInputAr {
  position: absolute;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0;
  color: #afafaf;
  text-transform: capitalize;
  opacity: 1;
  margin-right: 77%;
  direction: ltr;
}
.repInsc {
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 15px;
  padding-left: 5%;
  width: 150px;
}
.repInscAr {
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  font-family: cairo;
  font-weight: 400;
  font-size: 15px;
  padding-right: 5%;
}
.repInsc2 {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0;
  color: #ff9d00;
  opacity: 1;
  padding-left: 3%;
  width: 46%;
}
.repInsc2Ar {
  font-family: cairo;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0;
  color: #ff9d00;
  opacity: 1;
  padding-right: 3%;
  width: 46%;
}
.langueLogin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 6%;
}
.frLogin {
  letter-spacing: 0;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 15px;
  padding-top: 1%;
  margin-right: 1%;
  margin-left: 1%;
  cursor: pointer;
}
.arLogin {
  letter-spacing: 0;
  color: #68a4d3;
  text-transform: capitalize;
  opacity: 1;
  font-family: Cairo;
  font-weight: 400;
  font-size: 15px;
  margin-right: 1%;
  margin-left: 1%;
  cursor: pointer;
}
.frLoginFr {
  letter-spacing: 0;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 15px;
  padding-top: 1%;
  margin-right: 1%;
  margin-left: 1%;
  cursor: pointer;
}

.separLang {
  opacity: 1;
  color: white;
  opacity: 1;
}
.BtnReInsc {
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  padding-right: 2%;
  background: #0066b5 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  height: 34px;
  width: 186px;
  border: 0px solid;
}
.divBtnReInsc {
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 6%;
  padding-right: 4%;
}
@media screen and (max-width: 668px) {
  .home {
    max-width: 100%;
    min-height: 100vh;
  }
  .divToolbar {
    max-width: 100%;
    margin: unset;
  }
  .divDialog {
    max-width: 100%;
  }
  .home2 {
    width: 100%;
    max-width: unset;
  }
}
@media only screen and (device-width: 768px) {
  .home2 {
    max-width: 100%;
  }
  .home {
    max-width: 100%;
    min-height: 100vh;
  }
  .divToolbar {
    max-width: 100%;
    margin: unset;
  }
  .divDialog {
    max-width: 100%;
  }
  /* For general iPad layouts */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  .arLogin {
    font-size: 30px;
  }
  .home {
    max-width: unset;
    min-height: 100vh;
    width: 100%;
  }
  .home2 {
    max-width: 100%;
  }
  .divToolbar {
    max-width: 100%;
    margin: unset;
  }
  .divDialog {
    max-width: 100%;
  }
  .frLogin {
    font-size: 30px;
  }
  .frLoginFr {
    font-size: 30px;
  }
  .separLang {
    margin-top: 44px;
  }
  .BienvenueJawhara {
    width: 81%;
    font-size: 50px;
  }
  .labelSaisie {
    font-size: 30px;
  }
  .btn {
    border-radius: 44px;
    height: 72px;
    width: 204px;
    font-size: 34px;
  }
  .BienvenueJawharaAr {
    width: 80%;
    font-size: 50px;
  }
  .labelSaisieAr {
    font-size: 30px;
  }
  .btnAr {
    border-radius: 44px;
    height: 72px;
    width: 204px;
    font-size: 34px;
  }
  .inputTel {
    font-size: 30px;
    height: 100px;
  }
  .inputTelAr {
    font-size: 30px;
    height: 100px;
  }
  .spanInput {
    font-size: 33px;
  }
  .spanInputAr {
    font-size: 33px;
  }
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .spanInput {
    font-size: 33px;
  }
  .spanInputAr {
    font-size: 33px;
  }
  .inputTel {
    font-size: 30px;
    height: 100px;
  }
  .inputTelAr {
    font-size: 30px;
    height: 100px;
  }
  .frLoginFr {
    font-size: 30px;
  }
  .btn {
    border-radius: 44px;
    height: 72px;
    width: 204px;
    font-size: 34px;
  }
  .btnAr {
    border-radius: 44px;
    height: 72px;
    width: 204px;
    font-size: 34px;
  }
  .labelSaisie {
    font-size: 30px;
  }
  .labelSaisieAr {
    font-size: 30px;
  }
  .BienvenueJawhara {
    width: 81%;
    font-size: 50px;
  }
  .BienvenueJawharaAr {
    width: 81%;
    font-size: 50px;
  }
  .arLogin {
    font-size: 30px;
  }
  .separLang {
    margin-top: 44px;
  }
  .frLogin {
    font-size: 30px;
  }
  .home {
    max-width: unset;
    min-height: 100vh;
    width: 100%;
  }
  .home2 {
    max-width: 100%;
  }
  .divToolbar {
    max-width: 100%;
    margin: unset;
  }
  .divDialog {
    max-width: 100%;
  }
  /* For landscape layouts only */
}
.point {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ff9d00;
  position: relative;
  animation: fadein 2s;
  animation-iteration-count: infinite;
}
.point1 {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ff9d00;
  position: relative;
  animation: fadein1 2s;
  animation-iteration-count: infinite;
}
.point2 {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ff9d00;
  position: relative;
  animation: fadein2 2s;
  animation-iteration-count: infinite;
}

@keyframes fadein {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadein1 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadein2 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.CeC {
  color: #ff9d00;
  position: relative;
  animation: fadein 2s;
  animation-iteration-count: infinite;
}

.msgerrAR {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  display: flow-root !important;
}
.msgerrFR {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  text-align: center;
}
