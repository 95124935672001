.home {
  background-color: #0066b5;
  /* max-width: 400px; */
  margin: 0 auto;
  min-height: 100vh;
  /* height:116vh; */
}
.heightLogo {
  height: 50px;
}
.imgLogo {
  justify-content: center;
  display: flex;
  opacity: 1;
}
.oops {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 0;
  color: #ff9d00;
  text-transform: capitalize;
  opacity: 1;
  line-height: 140%;
  padding-top: 8%;
}
.oopsAr {
  font-family: cairo;
  font-weight: bold;
  font-size: 35px;
  letter-spacing: 0;
  color: #ff9d00;
  text-transform: capitalize;
  opacity: 1;
  padding-top: 5%;
}
.fautecontain {
  justify-content: center;
  display: flex;
  opacity: 1;
}
.faute {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 0;
  color: #ff9d00;
  text-transform: capitalize;
  opacity: 1;
  line-height: 140%;
}
.fauteAr {
  font-family: cairo;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0;
  color: #ff9d00;
  text-transform: capitalize;
  opacity: 1;
}
.btnquitter {
  background: rgb(207, 204, 201) 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  text-align: center;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0;
  color: rgb(75, 71, 71);
  text-transform: capitalize;
  border: 0px solid;
  height: 50px;
  width: 146px;
}
.imgleft {
  position: absolute;
  position: absolute;
  padding: 12px;
  cursor: pointer;
}
.divFormStep2 {
  display: flex;
  flex-direction: column;
  padding-top: 6%;

  padding-right: 11px;
  padding-left: 11px;
}
.divFormStep3 {
  display: flex;
  flex-direction: column;
  padding-top: 21%;

  padding-right: 11px;
  padding-left: 11px;
}
.labelStep2 {
  letter-spacing: 0;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  font-family: Montserrat;
  font-weight: lighter;
  font-size: 15px;
  padding-top: 12px;
  padding-bottom: 3%;
}
.labelStep2Ar {
  letter-spacing: 0;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  font-family: cairo;
  font-weight: lighter;
  font-size: 15px;
  padding-top: 12px;
  padding-bottom: 3%;
}
.inputStep2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  opacity: 1;
  height: 50px;
  outline: none;
  font-family: Montserrat;
  font-weight: lighter;
  font-size: 15px;
  letter-spacing: 0;
  color: #7f7f7f;
  text-transform: capitalize;
  opacity: 1;
  text-indent: 9px !important;
}
.inputStep2Rong {
  border: 1px solid #b70202;
  border-radius: 5px;
  opacity: 1;
  height: 50px;
  outline: none;
  font-family: Montserrat;
  font-weight: lighter;
  font-size: 15px;
  letter-spacing: 0;
  color: #7f7f7f;
  text-transform: capitalize;
  opacity: 1;
  text-indent: 9px !important;
}
.spanTelInvalid {
  letter-spacing: 0;
  color: #b70202;
  opacity: 1;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
}
.divEtapeStep1 {
  display: flex;
  flex-direction: row-reverse;
}
.btnContinuerStep {
}
.scoreF {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0;
  color: #454f63;
  /* text-transform: capitalize; */
  opacity: 1;
  padding-left: 20px;
  width: 280px;
  line-height: 140%;
  padding-right: 20px;
  text-align: center;
}
.scoreA {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 25px;
  letter-spacing: 0;
  color: #e4e4e4;
  /* text-transform: capitalize; */
  opacity: 1;
  padding-left: 20px;
  width: 280px;
  line-height: 140%;
  padding-right: 20px;
  text-align: center;
}
.ressayerF {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0;
  color: #454f63;
  opacity: 1;
  padding-right: 6px;
  padding-left: 6px;
  text-align: center;
}
.divBouton1 {
  display: flex;
  /* flex-direction: row-reverse; */
  padding-top: 25%;
  padding-right: 5%;
  padding-left: 5%;
  justify-content: center;
}
.divBouton22 {
  display: flex;
  /* flex-direction: row-reverse; */
  padding-top: 5%;
  padding-right: 5%;
  padding-left: 5%;
  justify-content: center;
}
.div5Profi1 {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: -15px;
}
.div5Profi11 {
  display: flex;
  justify-content: center;
  margin-bottom: -15px;
}
.div5Profil2 {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.ressayerA {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0;
  color: #e4e4e4;
  /* text-transform: capitalize; */
  opacity: 1;
  padding-left: 20px;
  width: 280px;
  line-height: 140%;
  padding-right: 20px;
  text-align: center;
}
.btnNext {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 66px;
  padding-right: 10px;
  padding-left: 10px;
}
.btnstep2 {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 6%;
}

.SelectForfait {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #e3e3e3 !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  height: 50px !important;
}
/* .MuiSelect-icon-99 {
    right: 90% !important;
    
    left: 90% !important;
} */
.divStep3 {
  padding-top: 22px;
}
.headerStep {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.etapeStep {
  padding-right: 13px;
  padding-left: 13px;
  letter-spacing: 0;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  font-family: Montserrat;
  font-weight: lighter;
  font-size: 15px;
}
.Bienvenue {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 25px;
  letter-spacing: 0;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  padding-left: 20px;
  width: 280px;
  line-height: 140%;
  padding-right: 20px;
}
.BienvenueAr {
  font-family: cairo;
  font-weight: bold;
  font-size: 25px;
  letter-spacing: 0;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  padding-left: 20px;
  width: 280px;
  line-height: 140%;
  padding-right: 20px;
}

.DivBienLangue {
  display: flex;
  flex-direction: column;
}
.langue {
  padding-left: 17px;
  padding-right: 17px;
  letter-spacing: 0;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  font-family: Montserrat;
  font-weight: lighter;
  font-size: 15px;
}
.langueAr {
  padding-left: 17px;
  padding-right: 17px;
  font-family: cairo;
  font-weight: lighter;
  font-size: 15px;
  letter-spacing: 0;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
}
.selectLangue {
  background: #ffffff 0% 0% no-repeat padding-box !important;

  border-radius: 5px !important;
  opacity: 1 !important;
  height: 50px !important;
  width: 100%;
  text-indent: 9px !important;
  border-bottom: 2px #7f7f7f !important;
}
.selectErr {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #b70202 !important;
  border-radius: 5px !important;
  opacity: 1;
  height: 50px !important;
  width: 100%;
  text-indent: 9px !important;
}
.formSelect {
  padding-left: 20px;
  padding-right: 20px;
}
select {
  background: url(../../assets/arrow.png) no-repeat 97% 50% white;
  -webkit-appearance: none;
}
.divbtnContinuer {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 20px;
}

.btnContinuer {
  text-align: center;
  font-family: Montserrat;
  font-weight: lighter;
  font-size: 17px;
  letter-spacing: 0;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  background: #ff9d00 0% 0% no-repeat padding-box;
  border-radius: 25px;
  width: 146px;
  height: 50px;
  border: 0px solid;
}
.endHeight {
  height: 140px;
}
.MuiDrawer-paperAnchorLeft {
  left: 1;
}
.divToolbar {
  max-width: 400px;
  margin: 0 auto;
}
.divDialog {
  max-width: 421px;
  margin: 0 auto;
}
.stepperBar1Fr {
  width: 30%;
  border: 1px solid;
  color: #ffffff;
}
.stepperBar2Fr {
  width: 70%;
  border: 1px solid;
  color: #66a3d3;
}
.stepperBar1ar {
  width: 30%;
  border: 1px solid;
  color: #ffffff;
}
.stepperBar2ar {
  width: 70%;
  border: 1px solid;
  color: #66a3d3;
}
.stepperBar3Fr {
  width: 70%;
  border: 1px solid;
  color: #ffffff;
}
.stepperBar4Fr {
  width: 30%;
  border: 1px solid;
  color: #66a3d3;
}
.stepperBar3ar {
  width: 70%;
  border: 1px solid;
  color: #ffffff;
}
.stepperBar4ar {
  width: 30%;
  border: 1px solid;
  color: #66a3d3;
}
@media screen and (max-width: 668px) {
  .home {
    max-width: 100%;
    min-height: 100vh;
  }
  .divToolbar {
    max-width: 100%;
    margin: unset;
  }
  .divDialog {
    max-width: 100%;
  }
  .home2 {
    width: 100%;
    max-width: unset;
  }
}
@media only screen and (device-width: 768px) {
  .home2 {
    max-width: 100%;
  }
  .home {
    max-width: 100%;
    min-height: 100vh;
  }
  .divToolbar {
    max-width: 100%;
    margin: unset;
  }
  .divDialog {
    max-width: 100%;
  }
  /* For general iPad layouts */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  .home {
    max-width: 100%;
    min-height: 100vh;
  }
  .home2 {
    max-width: 100%;
  }
  .divToolbar {
    max-width: 100%;
    margin: unset;
  }
  .divDialog {
    max-width: 100%;
  }
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .home {
    max-width: 100%;
    min-height: 100vh;
  }
  .home2 {
    max-width: 100%;
  }
  .divToolbar {
    max-width: 100%;
    margin: unset;
  }
  .divDialog {
    max-width: 100%;
  }
  /* For landscape layouts only */
}

.point {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ff9d00;
  position: relative;
  animation: fadein 2s;
  animation-iteration-count: infinite;
}
.point1 {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ff9d00;
  position: relative;
  animation: fadein1 2s;
  animation-iteration-count: infinite;
}
.point2 {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ff9d00;
  position: relative;
  animation: fadein2 2s;
  animation-iteration-count: infinite;
}

@keyframes fadein {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadein1 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadein2 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.CeC1 {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #ff9d00;
  margin-top: 5px;
  position: relative;
  animation: fadein 2s;
  animation-iteration-count: infinite;
}
