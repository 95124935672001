/* .slick-slider {
    margin-top: 20px !important;
} */
.home3 {
  max-width: 400px;
  /* margin: 0 auto; */
  min-height: 100vh;
  background-color: #0066b5;
}
.slick-slide img {
  width: 100%;
}
.iconetoilepFr {
  border-radius: 100%;
  cursor: pointer;
  padding-top: 34px;
  margin-left: 290px;
  padding-right: 10px;
}
.iconetoilepAr {
  border-radius: 100%;
  cursor: pointer;
  padding-top: 34px;
  margin-right: 290px;
  padding-left: 10px;
}
.pointBTotalaFR {
  text-align: right;
  opacity: 1;
  padding-top: 2%;
}
.pointBTotalaAR {
  text-align: left;
  opacity: 1;
  padding-top: 2%;
}
.pointBTAc {
  border-radius: 50%;
  color: #ffffff;
  position: relative;
  font-family: cairo;
  font-weight: bold;
  font-size: 17px;
  /* line-height: 140%; */
  padding-right: 25px;
  /* z-index: 99999; */
}
.pointTBAcAr {
  border-radius: 50%;
  color: #ffffff;
  position: relative;
  font-family: cairo;
  font-weight: bold;
  font-size: 17px;
  /* line-height: 140%; */
  padding-left: 15px;
  /* z-index: 99999; */
}
.questionPaper {
  background-color: #0066b5;
  /* Webkit-box-shadow: 1px 2px 6px #0000004B; */
  box-shadow: 1px 2px 6px #0000004b;
  padding: 10px 50px;
  margin: 5px 5px;
  min-width: 350;
}
.questionPaperactive {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background-color: "theme.palette.primary.main"; */
  /* WebkitBoxShadow: "1px 2px 6px #0000004B", */
  box-shadow: 1px 2px 6px #0000004b;
  padding: 10px 50px;
  margin: 5px 5px;
  border: none;
  outline: none;
  color: #000;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  cursor: pointer;
  min-width: 350;
}
.quiz {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0;
  color: #2f82c3;
  opacity: 1;
  padding-right: 13px;
  padding-left: 13px;
}
.divBoutonEnvoyer {
  display: flex;
  flex-direction: row-reverse;
  padding-left: 5%;
  justify-content: right;
}
.divBoutonFR {
  display: flex;
  /* flex-direction: row-reverse; */
  /* padding-top: 15%;
  padding-right: 5%;
  padding-left: 5%; */
  justify-content: center;
  padding-bottom: 20px;
}
.divBoutonAR {
  display: flex;
  /* flex-direction: row-reverse; */
  /* padding-top: 8%;
  padding-right: 5%;
  padding-left: 5%; */
  justify-content: center;
  padding-bottom: 20px;
}
.btnquitter {
  background: #8e816c 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  text-align: center;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0;
  color: #f4f3f1;
  text-transform: capitalize;
  border: 0px solid;
  height: 50px;
  width: 146px;
}
.btnquitterAr {
  background: #8e816c 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  text-align: center;
  font-family: cairo;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0;
  color: #f4f3f1;
  text-transform: capitalize;
  border: 0px solid;
  height: 50px;
  width: 146px;
}

.response {
  font-family: Montserrat;
  /* font-weight: bold; */
  font-size: 20px;
  letter-spacing: 0;
  color: #2f82c3;
  text-transform: capitalize;
  opacity: 1;
  padding-left: 20px;
  width: 250px;
  line-height: 140%;
  padding-right: 20px;
  text-align: center;
}
.responseAr {
  font-family: cairo;
  /* font-weight: bold; */
  font-size: 20px;
  letter-spacing: 0;
  color: #2f82c3;
  text-transform: capitalize;
  opacity: 1;
  padding-left: 20px;
  width: 250px;
  line-height: 140%;
  padding-right: 20px;
  text-align: center;
}
.questionB {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0;
  color: #e4e4e4;
  /* text-transform: capitalize; */
  opacity: 1;
  padding-left: 20px;
  width: 280px;
  line-height: 140%;
  padding-right: 20px;
  text-align: center;
}
.resultresp {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0;
  color: #5a5a5a;
  /* text-transform: capitalize; */
  opacity: 1;
  /* padding-left: 20px;
  width: 280px;
  line-height: 140%;
  padding-right: 20px;
  margin-bottom: -66px;
  margin-top: -42px; */
  text-align: center;
}
.resultrespAr {
  font-family: cairo;
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 0;
  color: #5a5a5a;
  /* text-transform: capitalize; */
  opacity: 1;
  /* padding-left: 20px;
  width: 280px;
  line-height: 140%;
  padding-right: 20px;
  text-align: center; */
  margin-top: -48px;
}
.div2Profil2 {
  display: flex;
  justify-content: center;
  /* padding-top: 9%; */
  padding-bottom: 2%;
}
.responsesdiv {
  display: flex;
  justify-content: center;
  /* margin-left: 10px; */
  /* margin-bottom: -66px; */
}
.questionBAr {
  font-family: cairo;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0;
  color: #e4e4e4;
  /* text-transform: capitalize; */
  opacity: 1;
  padding-left: 20px;
  width: 280px;
  line-height: 140%;
  padding-right: 20px;
  text-align: center;
}

.DivBienLangue1 {
  display: flex;
  flex-direction: column;
}
.slick-slide {
  /* margin: 0 -10px;  */
}
/* .slick-track{
      width: 1690px !important;
  
  } */
/* the parent */
.slick-list {
  /* margin: 0 -10px; */
}
.quizAr {
  font-family: cairo;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0;
  color: #2f82c3;
  opacity: 1;
  padding-right: 13px;
  padding-left: 13px;
}
.slick-prev {
  display: none !important;
}

.slick-next {
  display: none !important;
}
.imgAct {
  width: 93% !important;
}
.divSlider1 {
  padding-top: 7px !important;
  padding-right: 3% !important;
  padding-left: 3% !important;
}
.act2Para {
  letter-spacing: 0;
  color: #454f63;
  opacity: 1;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 12px;
  width: 140px;
  height: 35px;
}
.divActImgPara {
  display: flex !important;
  flex-direction: column !important ;
}
.act2Heure {
  letter-spacing: 0;
  color: #afafaf;
  opacity: 1;
  font-family: Montserrat;
  font-weight: lighter;
  font-size: 11px;
}
.act2Row {
  display: flex;
  flex-direction: row;
  padding-bottom: 4%;
  padding-right: 2%;
  padding-left: 2%;

  /* padding: 7px; */
}
.titreNews2 {
  letter-spacing: 0;
  color: #454f63;
  opacity: 1;
  font-family: Montserrat;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 700;
}
.titreNews2Ar {
  letter-spacing: 0;
  color: #454f63;
  opacity: 1;
  font-family: cairo;
  font-weight: 700;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.imgNews {
  height: 140px;
  margin-left: 3px;
  border-radius: 10px;
  width: 153px;
  object-fit: cover;
}
.paraNews2 {
  letter-spacing: 0;
  color: #7f7f7f;
  opacity: 1;
  font-family: Montserrat;
  font-weight: lighter;
  font-size: 12px;
  line-height: 19px;
  margin-block-end: unset;
  margin-block-start: unset;
}
.paraNews2Ar {
  letter-spacing: 0;
  color: #7f7f7f;
  opacity: 1;
  font-family: cairo;
  font-weight: lighter;
  font-size: 12px;
  line-height: 19px;
  margin-block-end: unset;
  margin-block-start: unset;
}
.divImg {
  /* padding-top: 5%; */
  /* width: 40%; */
}
.dateNews2 {
  letter-spacing: 0;
  color: #afafaf;
  opacity: 1;
  font-family: Montserrat;
  font-weight: lighter;
  font-size: 11px;
}
.dateNews2Ar {
  letter-spacing: 0;
  color: #afafaf;
  opacity: 1;
  font-family: cairo;
  font-weight: lighter;
  font-size: 11px;
}
.news {
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 16px;
  margin-left: 15px;
}

.voirPlus {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 20px;
  margin-left: 20px;
}
.voirPlusName {
  padding: 10px;
  letter-spacing: 0;
  color: #2f82c3;
  opacity: 1;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
}
.voirPlusNameAr {
  padding: 10px;
  letter-spacing: 0;
  color: #2f82c3;
  opacity: 1;
  font-family: cairo;
  font-weight: 600;
  font-size: 14px;
}

@media screen and (max-width: 668px) {
  .ImgSlider {
    height: 190px !important;
  }
}
@media only screen and (device-width: 768px) {
  .ImgSlider {
    height: 190px !important;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  .ImgSlider {
    height: 540px !important;
  }
  .imgNews {
    width: 397px !important;
    height: 263px !important;
  }
  .quiz {
    font-size: 42px !important;
  }
  .quizAr {
    font-size: 42px !important;
  }
  .titreNews2 {
    font-size: 40px !important;
  }
  .titreNews2Ar {
    font-size: 40px !important;
  }
  .dateNews2 {
    font-size: 30px !important;
  }
  .dateNews2Ar {
    font-size: 30px !important;
  }
  .voirPlusName {
    font-size: 35px !important;
  }
  .voirPlusNameAr {
    font-size: 35px !important;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .ImgSlider {
    height: 540px !important;
  }
  .imgNews {
    width: 397px !important;
    height: 263px !important;
  }
  .quiz {
    font-size: 42px !important;
  }
  .quizAr {
    font-size: 42px !important;
  }
  .dateNews2 {
    font-size: 30px !important;
  }
  .dateNews2Ar {
    font-size: 30px !important;
  }
  .voirPlusName {
    font-size: 35px !important;
  }
  .voirPlusNameAr {
    font-size: 35px !important;
  }
}
