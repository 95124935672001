/* .slick-slider {
    margin-top: 20px !important;
} */
.home2 {
  max-width: 400px;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #ffffff;
  /* border: 1px solid #acb3b8; */
}

.info {
  border-radius: 10px;
  width: 93% !important;
  height: 180px !important;
  border: 1px solid #0066b5;
  justify-content: center;
  display: flex;
  margin-left: 11px;
  margin-top: 60px;
  flex-direction: column;
  align-items: center;
}
.divFooter {
  box-shadow: 1px 2px 6px #0000004b;
  width: 91% !important;
  height: 273px !important;
  border-radius: 25px;
  margin-left: 17px;
}
.divFooterAr {
  box-shadow: 1px 2px 6px #0000004b;
  width: 91% !important;
  height: 273px !important;
  border-radius: 25px;
  margin: auto;
}
.divBottom {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
#footer {
  position: relative;
  width: 100% !important;
  height: 293px !important;
  background: transparent;
  /* margin-left: 18px; */
  color: #282828;
  text-align: center;
  font-size: 64px;
  /* border: 1px solid #0066b5; */
  border-radius: 25px;
}
#footerA {
  position: relative;
  width: 100% !important;
  height: 200px !important;
  background: transparent;
  margin-right: 18px;
  color: #282828;
  /* margin-bottom: 1em; */
  text-align: center;
  font-size: 64px;
  border: 1px solid #0066b5;
  border-radius: 25px;
  margin-top: 20px;
}
#footer p {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 1px;
  padding-right: 1px;
}
#footer .copyright {
  display: inline-block;
  padding-top: 0.6em;
  letter-spacing: 0.05em;
  color: #999;
  color: rgba(255, 255, 255, 0.5);
}
#background {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
#top,
#middle {
  height: 20.3334%;
}
#top {
  background: #ffffff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  margin-bottom: 72px;
  padding-top: 5px;
}
#middle {
  background: #ffffff;
  /* margin-top: -10px; */
  margin-bottom: 30px;
}
#bottom,
#participerQuizBtn2 {
  /* margin-top: -2px; */
  background: #0066b5;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  justify-content: center;
  display: flex;
  height: 53px;
}
.divBoutonParticiper {
  display: flex;
  flex-direction: row-reverse;
  /* padding-top: 15%; */
  padding-right: 5%;
  padding-left: 5%;
}
.imgleft {
  position: absolute;
  position: absolute;
  padding: 12px;
  cursor: pointer;
}
.btnParticiper {
  /* background: #0066b5 0% 0% no-repeat padding-box; */
  border-radius: 25px;
  opacity: 1;
  text-align: center;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  border: 0px solid;
  height: 50px;
  width: 146px;
}
.btnParticiperAr {
  background: #0066b5 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  text-align: center;
  font-family: cairo;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  border: 0px solid;
  height: 50px;
  width: 146px;
}
.labelQuizInfo {
  font-family: Montserrat;
  letter-spacing: 0;
  font-size: 13px;
  color: rgb(5, 5, 5);
  font-weight: bold;
  /* text-transform: capitalize; */
  color: #454f63;
  opacity: 1;
}
.labelInfo1 {
  font-family: Montserrat;
  letter-spacing: 0;
  font-size: 12px;
  color: rgb(128, 124, 124);
  /* text-transform: capitalize; */
  opacity: 1;
  text-align: center;
}
.labelInfoAr1 {
  font-family: Montserrat;
  letter-spacing: 0;
  font-size: 12px;
  color: rgb(128, 124, 124);
  /* text-transform: capitalize; */
  opacity: 1;
  text-align: center;
}
.labelQuizInfoAr {
  font-family: cairo;
  letter-spacing: 0;
  color: rgb(5, 5, 5);
  text-transform: capitalize;
  opacity: 1;
}
.slick-slide img {
  width: 100%;
}
.pointTAcF {
  border-radius: 50%;
  color: #2f82c3;
  position: relative;
  font-family: cairo;
  font-weight: bold;
  font-size: 25px;
  /* line-height: 140%; */
  padding-right: 25px;
}
.pointAcTA {
  border-radius: 50%;
  text-align: left;
  color: #2f82c3;
  position: relative;
  font-family: cairo;
  font-weight: bold;
  font-size: 25px;
  /* line-height: 140%; */
  padding-right: 200px;
}
.logoStart {
  width: 143px;
  height: 50px;
}
.pointTotal {
  justify-content: center;
  display: flex;
  opacity: 1;
}
.actualite {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0;
  color: #0066b5;
  opacity: 1;
  padding-right: 13px;
  padding-left: 13px;
}
.ImgSlider {
  border-radius: 10px;
  width: 98% !important;
  height: 215px !important;
}
.PlayerSlider {
  border-radius: 10px;
  width: 98% !important;
  height: 215px !important;
}
.slick-slide {
  /* margin: 0 -10px;  */
}
/* .slick-track{
    width: 1690px !important;

} */
/* the parent */
.slick-list {
  /* margin: 0 -10px; */
}
.actualiteAr {
  font-family: cairo;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0;
  color: #0066b5;
  opacity: 1;
  padding-right: 13px;
  padding-left: 13px;
}
.slick-prev {
  display: none !important;
}

.slick-next {
  display: none !important;
}
.imgAct {
  width: 93% !important;
}
.divSlider {
  padding-top: 7px !important;
  padding-right: 3% !important;
  padding-left: 3% !important;
  justify-content: center;
  /* display: flex; */
}
.act2Para {
  letter-spacing: 0;
  color: #454f63;
  opacity: 1;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 12px;
  width: 140px;
  height: 35px;
}
.divActImgPara {
  display: flex !important;
  flex-direction: column !important ;
}
.act2Heure {
  letter-spacing: 0;
  color: #afafaf;
  opacity: 1;
  font-family: Montserrat;
  font-weight: lighter;
  font-size: 11px;
}
.act2Row {
  display: flex;
  flex-direction: row;
  padding-bottom: 4%;
  padding-right: 2%;
  padding-left: 2%;

  /* padding: 7px; */
}
.titreNews2 {
  letter-spacing: 0;
  color: #454f63;
  opacity: 1;
  font-family: Montserrat;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 700;
}
.titreNews2Ar {
  letter-spacing: 0;
  color: #454f63;
  opacity: 1;
  font-family: cairo;
  font-weight: 700;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.imgNews1 {
  height: 140px;
  margin-left: 3px;
  border-radius: 10px;
  width: 153px;
  object-fit: cover;
}
.paraNews2 {
  letter-spacing: 0;
  color: #7f7f7f;
  opacity: 1;
  font-family: Montserrat;
  font-weight: lighter;
  font-size: 12px;
  line-height: 19px;
  margin-block-end: unset;
  margin-block-start: unset;
}
.paraNews2Ar {
  letter-spacing: 0;
  color: #7f7f7f;
  opacity: 1;
  font-family: cairo;
  font-weight: lighter;
  font-size: 12px;
  line-height: 19px;
  margin-block-end: unset;
  margin-block-start: unset;
}
.divImg {
  /* padding-top: 5%; */
  /* width: 40%; */
}
.dateNews2 {
  letter-spacing: 0;
  color: #afafaf;
  opacity: 1;
  font-family: Montserrat;
  font-weight: lighter;
  font-size: 11px;
}
.dateNews2Ar {
  letter-spacing: 0;
  color: #afafaf;
  opacity: 1;
  font-family: cairo;
  font-weight: lighter;
  font-size: 11px;
}
.news {
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 16px;
  margin-left: 15px;
}

.voirPlus {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 20px;
  margin-left: 20px;
}
.voirPlusName {
  padding: 10px;
  letter-spacing: 0;
  color: #0066b5;
  opacity: 1;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
}
.voirPlusNameAr {
  padding: 10px;
  letter-spacing: 0;
  color: #0066b5;
  opacity: 1;
  font-family: cairo;
  font-weight: 600;
  font-size: 14px;
}

@media screen and (max-width: 668px) {
  .ImgSlider {
    height: 190px !important;
  }
}
@media only screen and (device-width: 768px) {
  .ImgSlider {
    height: 190px !important;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  .ImgSlider {
    height: 540px !important;
  }
  .imgNews1 {
    width: 397px !important;
    height: 263px !important;
  }
  .actualite {
    font-size: 42px !important;
  }
  .actualiteAr {
    font-size: 42px !important;
  }
  .titreNews2 {
    font-size: 40px !important;
  }
  .titreNews2Ar {
    font-size: 40px !important;
  }
  .dateNews2 {
    font-size: 30px !important;
  }
  .dateNews2Ar {
    font-size: 30px !important;
  }
  .voirPlusName {
    font-size: 35px !important;
  }
  .voirPlusNameAr {
    font-size: 35px !important;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .ImgSlider {
    height: 540px !important;
  }
  .imgNews1 {
    width: 397px !important;
    height: 263px !important;
  }
  .actualite {
    font-size: 42px !important;
  }
  .actualiteAr {
    font-size: 42px !important;
  }
  .dateNews2 {
    font-size: 30px !important;
  }
  .dateNews2Ar {
    font-size: 30px !important;
  }
  .voirPlusName {
    font-size: 35px !important;
  }
  .voirPlusNameAr {
    font-size: 35px !important;
  }
}
