.home2 {
  max-width: 400px;
  margin: 0 auto;
  min-height: 90vh;
}

.connectNotif {
  background: #d66a6a 0% 0% no-repeat padding-box;
  opacity: 1;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  text-align: center;
  margin-left: -6px;
  margin-top: -15px;
}
.divToolbar {
  max-width: 400px;
  margin: 0 auto;
}
.divDialog {
  max-width: 421px;
  margin: 0 auto;
}
.divlogo {
  text-align: center;
  align-items: center;
  padding: 20px;
}

.rowProfil {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background: #0066B5 0% 0% no-repeat padding-box; */
  opacity: 1;
  padding-left: 5%;
  padding-right: 5%;
  height: 67px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  max-width: 302px;
  margin: 0 auto;
}
.imgPBlock {
  display: flex;
  flex-direction: row;
}
.logoAcceuil {
  padding: 14px;
}
.pTelBlock {
  letter-spacing: 0;
  color: #454f63;
  opacity: 1;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 12px;
}
.BtnLive {
  width: 78px;
  height: 35px;
  background: #d66a6a 0% 0% no-repeat padding-box;
  border: 0px solid;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0;
  color: #ffffff;
  display: inline-flex;
  border-radius: 5px;
}
.divFMProfil {
  display: inline-flex;
}
.divFm {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.imgLive {
  background: url("../../assets/live-icon.svg") 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-right: 16px;
}
.imgLiveAr {
  /* background:  url('../../assets/live-icon.svg') 0% 0% no-repeat padding-box; */
  opacity: 1;
  padding-left: 16px;
}
.menuLogo {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
}
.logo {
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  width: 143px;
  height: 50px;
}
.listMenu {
  letter-spacing: 0;
  color: #454f63;
  opacity: 1;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  padding-right: 20px;
  padding-left: 20px;
}
.listMenuAr {
  letter-spacing: 0;
  color: #454f63;
  opacity: 1;
  font-family: cairo;
  font-weight: 600;
  font-size: 14px;
  padding-right: 20px;
  padding-left: 20px;
}
.iconAndList {
  display: flex;
  flex-direction: row;
}
.pointTAc {
  border-radius: 50%;
  color: #2f82c3;
  position: relative;
  font-family: cairo;
  font-weight: bold;
  font-size: 17px;
  /* line-height: 140%; */
  padding-right: 25px;
}
.pointAcTAr {
  border-radius: 50%;
  text-align: left;
  color: #2f82c3;
  position: relative;
  font-family: cairo;
  font-weight: bold;
  font-size: 17px;
  /* line-height: 140%; */
  padding-left: 25px;
}
.pointBTotalFR {
  opacity: 1;
  /* padding-bottom: 10%; */
}
.pointBTotalAR {
  opacity: 1;
}
.imgProfilList {
  padding: 13px;
  /* width: 150px;
  height: 150px; */
  border-radius: 100%;
  cursor: pointer;
  /* padding: 23px; */
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
}
.iconetoileFr {
  border-radius: 100%;
  cursor: pointer;
  padding-top: 23%;
  /* margin-left: 152px; */
  padding-right: 10px;
}
.iconetoileAr {
  border-radius: 100%;
  cursor: pointer;
  padding-top: 23%;
  /* margin-left: 152px; */
  padding-left: 10px;
}
.iconetoile2Fr {
  border-radius: 100%;
  cursor: pointer;
  padding-top: 34px;
  /* margin-left: 160px; */
  padding-right: 10px;
}
.iconetoile2Ar {
  padding-right: 10px;
  border-radius: 100%;
  cursor: pointer;
  /* margin-right: 116px; */
}
.div2Profil {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 13%;
}
.btnLiveStr {
  background: #d66a6a;
  border-radius: 5px;
  opacity: 1;
  width: 89px;
  height: 34px;
  outline: none;
  border: none;
  background-image: url("../../assets/player.svg");
  background-repeat: no-repeat;
}
.divComLive {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 668px) {
  .divToolbar {
    max-width: 100%;
    margin: unset;
  }
  .divDialog {
    max-width: 100%;
  }
  .home2 {
    width: 100%;
    max-width: unset;
  }
  .rowProfil {
    max-width: unset;
    margin: unset;
  }
}
@media only screen and (device-width: 768px) {
  .home2 {
    max-width: 100%;
  }

  .divToolbar {
    max-width: 100%;
    margin: unset;
  }
  .divDialog {
    max-width: 100%;
  }
  .rowProfil {
    max-width: unset;
    margin: unset;
  }
  /* For general iPad layouts */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  .home2 {
    max-width: 100%;
    min-height: 100vh;
  }
  .divToolbar {
    max-width: 100%;
    margin: unset;
  }
  .divDialog {
    max-width: 100%;
  }
  .rowProfil {
    max-width: unset;
    margin: unset;
    height: 109px;
  }
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .home2 {
    max-width: 100%;
    min-height: 100vh;
  }
  .divToolbar {
    max-width: 100%;
    margin: unset;
  }
  .divDialog {
    max-width: 100%;
  }
  .rowProfil {
    max-width: unset;
    margin: unset;
    height: 109px;
  }
  /* For landscape layouts only */
}
